import React from 'react';
import {InfoSec,Heading, Subtitle,BoldText} from './InfoSection.elements'
import { Container } from '../../globalStyles'

const UserAgreementPT = () => {
    return (
        <InfoSec lightBg={true}>
            <Container>
                <Heading>Bem-vindo ao serviço Xscore.ai!</Heading>
                <Subtitle>Para usar os serviços da Xscore.ai, você deve ler e concordar com o "Acordo de Privacidade da Xscore.ai" (doravante referido como "este Acordo"). Leia atentamente e entenda completamente cada cláusula, especialmente aquelas que excluem ou limitam responsabilidades, bem como quaisquer acordos específicos de determinados serviços. Limitações e exonerações podem ser destacadas em negrito. Você não tem permissão para usar os serviços fornecidos pela Kangaroo2023 a menos que tenha lido e aceitado todos os termos deste Acordo. Seu uso dos serviços mencionados acima será considerado como seu reconhecimento e aceitação dos termos deste Acordo.</Subtitle>
                <Heading>1.	Âmbito do Acordo </Heading>
                <Subtitle>1.1 Este Acordo é um contrato entre você e a Xscore.ai sobre o uso pelo usuário (o "uso" inclui, mas não se limita a download, instalação, inicialização, navegação, registro, login, etc., doravante denominado "uso") dos serviços relacionados fornecidos pela Xscore.ai. "Xscore.ai" refere-se a [nome da empresa/nome do desenvolvedor] e suas unidades operacionais relacionadas aos serviços. "Este site" refere-se ao site e ao cliente da Xscore.ai. "Usuário" refere-se ao usuário dos serviços relevantes, denominado "você" neste Acordo. Os usuários registrados e não registrados são considerados "usuários" nos termos deste Acordo. Os usuários não registrados de produtos e/ou serviços Xscore.ai tornam-se "usuários não registrados" da Xscore.ai a partir do momento em que começam a usar os produtos e/ou serviços Xscore.ai e devem cumprir todos os termos deste Acordo, exceto os termos exclusivos para usuários registrados. </Subtitle>
                <Subtitle>1.2 Este Acordo se aplica a todos os produtos e/ou serviços da Xscore.ai, incluindo, mas não se limitando a, serviços de áudio e vídeo, serviços de publicidade, entretenimento interativo, serviços de associação e/ou outros produtos e/ou serviços fornecidos pela Xscore.ai. </Subtitle>
                <Subtitle>1.3 Ao usar serviços específicos deste site, pode haver acordos separados, regras de negócios relacionadas, etc. (coletivamente referidos como "Acordos separados"). Uma vez que esses Acordos separados sejam oficialmente publicados, eles se tornarão parte integrante deste Acordo, e você também será obrigado a cumprir. O uso pelo usuário dos serviços específicos fornecidos pela Xscore.ai será considerado como concordância com os Acordos separados específicos desse serviço e quaisquer avisos publicados pela Xscore.ai no referido serviço específico.</Subtitle>
                <Heading>2.	Segurança de Conta e Senha </Heading>
                <Subtitle>2.1 Ao usar a Xscore.ai, você precisa registrar uma conta. </Subtitle>
                <Subtitle>2.2 A Xscore.ai lembra especialmente para você gerenciar sua conta e a segurança da senha adequadamente. Após o uso, você deve fazer logout com segurança. Você é o único responsável por qualquer perda decorrente de má gestão de sua conta ou perda de senha. </Subtitle>
                <Subtitle>2.3 A Xscore.ai lhe dá o direito de cancelar sua conta. Você pode solicitar o cancelamento online ou entrar em contato com o serviço ao cliente da Xscore.ai, ou também pode cancelar sua conta através de outros métodos publicamente anunciados (a menos que a lei ou este Acordo estabeleça o contrário). Uma vez que você cancele sua conta, não poderá mais usar os serviços da Xscore.ai e perderá automaticamente todos os direitos existentes. Por favor, proceda com cuidado. Exceto conforme exigido por lei, após o cancelamento, a Xscore.ai cessará a prestação de serviços, e todo o conteúdo, informações, dados e registros sob os produtos e serviços utilizados por meio dessa conta serão excluídos ou anonimizados. </Subtitle>
                <Subtitle>2.3.1 Clique em Perfil, clique em Cancelar conta e, em seguida, confirme que todos os dados serão excluídos do sistema. </Subtitle>
                <Subtitle>2.4 Uso do TAC: </Subtitle>
                <Subtitle>2.4.1 Autenticação: TAC é um código numérico de seis dígitos enviado para o seu número de celular registrado para autenticação de transação. </Subtitle>
                <Subtitle>2.4.2 Acesso seguro: O objetivo do TAC é aumentar a segurança de suas transações. Mantenha seu TAC em sigilo e não o compartilhe com ninguém.</Subtitle>
                <Heading>3.	Proteção de Informações Pessoais do Usuário </Heading>
                <Subtitle>3.1 Proteger as informações pessoais do usuário é um princípio fundamental da Xscore.ai. </Subtitle>
                <Subtitle>3.2 Durante o processo de registro de conta ou uso dos serviços da Xscore.ai, você pode precisar fornecer algumas informações necessárias. Se houver requisitos especiais nas leis e regulamentos do país, você precisa fornecer informações verdadeiras de identificação. Se as informações fornecidas por você não estiverem completas, você pode ser incapaz de usar os serviços da Xscore.ai ou pode ser restrito durante o uso. </Subtitle>
                <Subtitle>3.3 Normalmente, você pode navegar e modificar as informações que você forneceu. No entanto, por motivos de segurança e identificação (por exemplo, serviço de reclamação de número), você pode não conseguir modificar as informações de registro inicial e outras informações de verificação fornecidas durante o registro. </Subtitle>
                <Subtitle>3.4 A Xscore.ai adotará várias tecnologias e procedimentos de segurança para estabelecer um sistema de gerenciamento perfeito para evitar o acesso, uso ou divulgação não autorizados de suas informações pessoais. </Subtitle>
                <Subtitle>3.5 Sem o seu consentimento, a Xscore.ai não divulgará suas informações pessoais a qualquer empresa, organização ou indivíduo que não seja a Xscore.ai, exceto quando exigido por leis e regulamentos. </Subtitle>
                <Subtitle>3.6 A Xscore.ai dá grande importância à proteção das informações pessoais de menores. Se você tiver menos de 18 anos, deve obter o consentimento prévio de seus pais ou responsáveis legais (doravante denominados "responsáveis legais") antes de usar os serviços da Xscore.ai. Os responsáveis legais devem orientar os menores sobre questões de segurança online e prevenir problemas. Se os responsáveis legais concordarem em permitir que menores usem os serviços da Xscore.ai, eles devem solicitar em nome dos menores (se envolver consumo), e supervisionar e orientar corretamente os menores em relação ao uso dos serviços da Xscore.ai. O uso de serviços da Xscore.ai por menores e o exercício e cumprimento de direitos e obrigações nos termos deste Acordo são considerados consentimento obtido dos responsáveis legais. </Subtitle>
                <Subtitle>3.7 Para mais informações sobre regras de processamento e proteção de informações pessoais, controle do usuário sobre informações pessoais, etc., por favor, consulte o texto completo da política de privacidade do produto deste site.</Subtitle>
                <Heading>4.	Prestação de Serviços no Estado Atual</Heading>
                <Subtitle>4.1 Você entende e concorda que os serviços da Xscore.ai são fornecidos com base na tecnologia e nas condições atuais disponíveis. A Xscore.ai fará o melhor esforço para fornecer a você serviços, garantindo a continuidade e segurança dos serviços. No entanto, a Xscore.ai não pode prever e prevenir todos os riscos causados por força maior, incluindo, mas não se limitando a, desastres naturais, vírus, cavalos de Troia, ataques de hackers, greves, ações governamentais, que podem resultar em interrupções de serviço, perda de dados e outras perdas. </Subtitle>
                <Subtitle>4.2 Você reconhece e concorda que, devido a problemas de largura de banda e conexão de rede, podem ocorrer longos períodos de buffering, atraso ou situações semelhantes durante a prestação de produtos e/ou serviços pela Xscore.ai. Essas situações não são culpa da Xscore.ai, e a Xscore.ai não é responsável por isso. Para minimizar ou evitar tais situações e melhorar ainda mais a experiência do usuário, você reconhece que a Xscore.ai pode adotar várias tecnologias para otimizar produtos e/ou serviços.</Subtitle>
                <Heading>5.	Dispositivos Próprios </Heading>
                <Subtitle>5.1 Você deve entender que, ao usar os serviços da Xscore.ai, você precisa preparar dispositivos terminais relacionados aos serviços (como computadores, modems, etc.) e arcar com os custos associados (como tarifas telefônicas, tarifas de Internet, etc.). </Subtitle>
                <Subtitle>5.2 Você entende e concorda que o uso dos serviços da Xscore.ai pode consumir recursos de seu dispositivo terminal e largura de banda.</Subtitle>
                <Heading>6.	Produtos ou Serviços Fornecidos por Terceiros</Heading>
                <Subtitle>Ao usar produtos ou serviços fornecidos por terceiros na plataforma da Xscore.ai, além de cumprir as disposições deste Acordo, você também deve cumprir os termos de serviço de terceiros. Dentro do escopo definido pela lei e pelos acordos, a Xscore.ai e os terceiros são responsáveis por disputas potenciais.</Subtitle>
                <Heading>7.	Software nos Serviços da Xscore.ai </Heading>
                
                <Subtitle>7.1 Durante o uso dos serviços da Xscore.ai, você pode precisar baixar software. Para esse tipo de software, a Xscore.ai concede a você uma licença pessoal, intransferível e não exclusiva. Você só pode usar esse software para acessar ou usar os serviços da Xscore.ai. </Subtitle>
                <Subtitle>7.2 Para melhorar a experiência do usuário, garantir a segurança dos serviços e manter a consistência das funções do produto, a Xscore.ai pode atualizar o software. Você deve atualizar o software relevante para a versão mais recente, e a Xscore.ai não garante seu uso normal.</Subtitle>
                <Heading>8.	Declaração de Propriedade Intelectual </Heading>
                <Subtitle>8.1 Os direitos de propriedade intelectual do conteúdo informativo (incluindo, mas não se limitando a, páginas da web, texto, imagens, áudio, vídeo, gráficos, etc.) fornecido pelos serviços da Xscore.ai pertencem à Xscore.ai. Isso não inclui as informações fornecidas pelos usuários que já possuíam direitos autorais legais antes de usar os serviços da Xscore.ai. </Subtitle>
                <Subtitle>8.2 A menos que indicado de outra forma, os direitos autorais, patentes e outros direitos de propriedade intelectual do software dependente dos serviços da Xscore.ai pertencem à Xscore.ai. </Subtitle>
                <Subtitle>8.3 Os direitos autorais ou marcas comerciais dos logotipos usados nos serviços da Xscore.ai pertencem à Xscore.ai. </Subtitle>
                <Subtitle>8.4 Outros direitos de propriedade intelectual legais detidos pela Xscore.ai são protegidos por lei. Sem a permissão por escrito da Xscore.ai, você não pode usar ou criar obras derivadas relacionadas de nenhuma forma. </Subtitle>
                <Subtitle>8.5 A menos que a lei estabeleça o contrário, sem a autorização escrita da Xscore.ai, você e/ou terceiros não podem copiar, reproduzir, citar, vincular, capturar ou usar todas ou parte das informações e conteúdos deste site de forma ilegal; caso contrário, a Xscore.ai tem o direito de responsabilizá-lo legalmente.</Subtitle>
                <Heading>9.	Diretrizes de Comportamento do Usuário </Heading>
                
                <Subtitle>9.1 Ao usar os serviços da Xscore.ai, você deve cumprir as leis e regulamentos e não deve se envolver em atividades ilegais, incluindo, mas não se limitando a: </Subtitle>
                <Subtitle>(1) Publicar, disseminar ou armazenar conteúdo que prejudique a segurança nacional, perturbe a estabilidade social, viole a ordem pública e a moral, seja insultuoso, difamatório, obsceno, violento ou viole qualquer lei ou regulamento nacional. </Subtitle>
                <Subtitle>(2) Publicar, disseminar ou armazenar conteúdo que viole os direitos legais de terceiros, como direitos de propriedade intelectual e segredos comerciais. </Subtitle>
                <Subtitle>(3) Falsificar fatos maliciosamente, ocultar a verdade para enganar ou enganar outras pessoas. </Subtitle>
                <Subtitle>(4) Publicar, disseminar ou armazenar informações publicitárias e lixo eletrônico. </Subtitle>
                <Subtitle>(5) Envolver-se em atividades prejudiciais à reputação e interesses da Xscore.ai ou de terceiros. </Subtitle>
                <Subtitle>(6) Usar apelidos ou assinaturas com significados ilegais, obscenos, insultuosos ou agressivos. </Subtitle>
                <Subtitle>(7) Outros comportamentos proibidos por leis e regulamentos. </Subtitle>
                <Subtitle>9.2 Se você violar as disposições deste Acordo, as autoridades governamentais ou instituições relevantes podem tomar medidas legais contra você, incluindo multas ou outras punições, e podem solicitar a assistência da Xscore.ai. Se causar danos, você é obrigado a compensar de acordo com a lei, e a Xscore.ai não será responsável. </Subtitle>
                <Subtitle>9.3 Se a Xscore.ai descobrir ou receber relatórios de terceiros de que o conteúdo que você postou viola as disposições deste Acordo, a Xscore.ai tem o direito de julgar independentemente e usar meios técnicos para excluir, bloquear ou desconectar o link. Além disso, com base na natureza do comportamento do usuário, a Xscore.ai tem o direito de tomar medidas, incluindo, mas não se limitando a, suspender ou encerrar serviços, restringir, congelar ou encerrar contas de usuário da Xscore.ai e responsabilizar legalmente. </Subtitle>
                <Subtitle>9.4 Se você violar as disposições deste Acordo e causar danos a terceiros, você será o único responsável; se a Xscore.ai sofrer perdas, terá o direito de pedir compensação a você.</Subtitle>
                <Heading>10.	Cumprimento das Leis e Regulamentos Locais </Heading>
                <Subtitle>10.1 Ao usar os serviços da Xscore.ai, você deve cumprir as leis e regulamentos locais e respeitar os costumes locais. Se suas ações violarem as leis, regulamentos locais ou costumes locais, você será o único responsável. </Subtitle>
                <Subtitle>10.2 Devido ao uso dos serviços da Xscore.ai, você deve evitar envolver a Xscore.ai em eventos políticos e públicos; caso contrário, a Xscore.ai tem o direito de suspender ou encerrar seus serviços para você.</Subtitle>
                <Heading>11.	Conteúdo Gerado pelo Usuário e Tratamento de Reclamações de Terceiros </Heading>
                <Subtitle>11.1 Você entende e concorda plenamente que a plataforma fornecida pela Xscore.ai é apenas para os usuários enviarem, compartilharem, transmitirem e obterem informações. O conteúdo divulgado ou transmitido através desta plataforma não reflete as opiniões ou políticas da Xscore.ai, e a Xscore.ai não se responsabiliza por isso. Você é responsável pelo conteúdo enviado ou transmitido pelos serviços da Xscore.ai, incluindo, mas não se limitando a, páginas da web, texto, imagens, áudio, vídeo, gráficos, etc. </Subtitle>
                <Subtitle>11.2 Ao enviar, publicar ou transmitir imagens, texto, áudio, vídeo ou outro conteúdo neste site, você é considerado o proprietário dos direitos autorais ou um agente legalmente autorizado do conteúdo acima. A menos que acordado de outra forma, seu comportamento público de enviar, divulgar e compartilhar tal conteúdo neste site representa que você concede à Xscore.ai o direito global, permanente, irrevogável e gratuito de exercer todos os direitos autorais (incluindo, mas não se limitando a, cópia, distribuição, locação, exposição, execução, exibição, transmissão, transmissão, filmagem, adaptação, tradução e criação de conteúdo relacionado ao desenvolvimento, promoção e serviço), mas excluindo os direitos pessoais do proprietário dos direitos autorais. </Subtitle>
                <Subtitle>11.3 Você reconhece e concorda que, a menos que exigido por lei, a Xscore.ai não tem obrigação de armazenar o conteúdo que você enviou, publicou ou compartilhou e que você deve fazer backup por conta própria. Se a lei exigir, a Xscore.ai monitorará o conteúdo que você carrega na plataforma. Você também reconhece e concorda que, a menos que exigido por lei, a Xscore.ai não é responsável pela confidencialidade de manter tais conteúdos, independentemente de serem públicos conhecidos ou não. </Subtitle>
                <Subtitle>11.4 Se a Xscore.ai receber uma notificação do detentor dos direitos, alegando que o conteúdo que você enviou ou transmitiu viola seus direitos relevantes, você concorda que a Xscore.ai tem o direito de julgar independentemente e tomar medidas como excluir, bloquear ou desconectar o link. </Subtitle>
                <Subtitle>11.5 Se você violar este Acordo e prejudicar os interesses legais de terceiros (incluindo, mas não se limitando a, direitos autorais, direitos de marca, direitos autorais e direitos adjacentes, direitos de imagem, direitos de privacidade e direitos de reputação), você deve ser responsável por isso. Se suas ações ilegais causarem reclamações, relatórios, consultas, reivindicações, ações judiciais ou danos à reputação, prestígio ou finanças da Xscore.ai ou de suas afiliadas, você deve tomar todas as medidas possíveis para garantir que a Xscore.ai e suas afiliadas não sejam afetadas por tais reivindicações ou ações judiciais. Ao mesmo tempo, você é responsável por todas as perdas econômicas diretas e indiretas da Xscore.ai e de suas afiliadas causadas por este incidente.</Subtitle>
                <Heading>12.	Entrada em Vigor e Modificação do Acordo </Heading>
                <Subtitle>12.1 Seu uso dos serviços da Xscore.ai é considerado que você leu este acordo e aceitou a restrição deste acordo. </Subtitle>
                <Subtitle>12.2 A Xscore.ai tem o direito de modificar os termos deste acordo conforme necessário. Você pode visualizar a versão mais recente dos termos do acordo na página de serviços relevante. </Subtitle>
                <Subtitle>12.3 Após a modificação dos termos deste acordo, se você continuar a usar o software ou serviço fornecido pela Xscore.ai, será considerado que você aceitou o acordo modificado. Se você não aceitar o acordo modificado, deverá parar de usar o software ou serviço fornecido pela Xscore.ai.</Subtitle>
                <Heading>13.	Alteração, Interrupção e Encerramento do Serviço </Heading>
                <Subtitle>13.1 A Xscore.ai pode alterar o conteúdo do serviço e também pode interromper, suspender ou encerrar o serviço. </Subtitle>
                <Subtitle>13.2 Em qualquer das seguintes circunstâncias, a Xscore.ai tem o direito de interromper ou encerrar o serviço prestado a você sem aviso prévio: </Subtitle>
                <Subtitle>(1) De acordo com os requisitos legais, você deve fornecer informações verdadeiras, mas as informações pessoais fornecidas por você não são verdadeiras ou são inconsistentes com as fornecidas no momento do registro, e você não pode fornecer uma prova razoável. </Subtitle>
                <Subtitle>(2) Você violou as disposições legais ou regulamentares pertinentes ou as disposições deste acordo. </Subtitle>
                <Subtitle>(3) De acordo com os requisitos legais ou regulatórios ou as solicitações das autoridades reguladoras. </Subtitle>
                <Subtitle>(4) Por razões de segurança ou outras circunstâncias necessárias.</Subtitle>
                <Heading>14.	Outros Assuntos </Heading>
                <Subtitle>14.1 Dado o rápido e diversificado desenvolvimento da indústria online, as disposições deste acordo entre você e a Xscore.ai não podem listar e abranger totalmente todos os seus direitos e obrigações em relação à Xscore.ai. Os acordos existentes também não podem garantir atender plenamente às necessidades futuras do desenvolvimento. Portanto, as declarações relevantes, políticas, regras e acordos publicados no site da Xscore.ai são acordos complementares a este acordo e são indivisíveis deste acordo e têm a mesma</Subtitle>

                <BoldText><center>Copyright 2024, Xscore.ai, All Rights Reserved</center></BoldText>
            </Container>
        </InfoSec>
    );
  }
  
  export default UserAgreementPT