export const homeObjOne = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Xscore.ai',
    description:
      ' 每一次运动都讲述一个故事，体验未写下的激动！ 您即时满足事件需求的智能体育助手！',
    buttonLabel: '下载！',
    imgStart: '',
    img: require('../../images/logo.png'),
    alt: '信用卡',
    start: ''
  };
  
  export const homeObjTwo = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '主要特点',
    headline: '关键时刻',
    description:
      "通过实时体育新闻和事件更新，保持紧跟比赛，确保您捕捉到每一个关键时刻。",
    buttonLabel: '了解更多',
    imgStart: '',
    img: require('../../images/img1.png'),
    alt: 'Vault',
    start: ''
  };
  
  export const homeObjThree = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false,
    topLine: '主要特点',
    headline:
      '赛事详情',
    description:
      "立即获取事件详情、赛程和实时结果。",
    // buttonLabel: '查看详情',
    imgStart: 'start',
    img: require('../../images/img2.png'),
    alt: 'Vault',
    start: 'true'
  };
  
  export const homeObjFour = {
    primary: false,
    lightBg: true,
    lightTopLine: false,
    lightText: false,
    lightTextDesc: false, 
    topLine: '主要特点',
    headline: '多元化',
    description:
      '涵盖各种运动和联赛 – 足球、篮球、网球、棒球等。',
    buttonLabel: '立即注册',
    imgStart: 'start',
    img: require('../../images/img3.png'),
    alt: 'Vault',
    start: 'true'
  };
  export const homeObjFive = {
    primary: true,
    lightBg: false,
    lightTopLine: true,
    lightText: true,
    lightTextDesc: true,
    topLine: '主要特点',
    headline: '个性化推荐',
    description:
      "定制体验  – 根据您的兴趣提供个性化推荐。",
    // buttonLabel: 'Learn More',
    imgStart: '',
    img: require('../../images/img4.png'),
    alt: 'Vault',
    start: ''
  };
