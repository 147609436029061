import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom' 
import GlobalStyles from './globalStyles'
import { Footer } from './components';
import Home from './pages/HomePage/Home';
import HomeCN from './pages/HomePage/HomeCN';
import HomePT from './pages/HomePage/HomePT';
import Privacy from './pages/PrivacyPolicyPage/Privacy';
import PrivacyPT from './pages/PrivacyPolicyPage/PrivacyPT';
import PrivacyCN from './pages/PrivacyPolicyPage/PrivacyCN';
import UserAgreement from './pages/UserAgreementPage/UserAgree';
import UserAgreementPT from './pages/UserAgreementPage/UserAgreePT';
import UserAgreementCN from './pages/UserAgreementPage/UserAgreeCN';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    
      <Router>
          <GlobalStyles />
          <ScrollToTop />
          {/* <Navbar /> */}
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/pt' exact component={HomePT} />
            <Route path='/pt/privacy-policy.html' exact component={PrivacyPT} />
            <Route path='/pt/user-agreement.html' exact component={UserAgreementPT} />
            <Route path='/en/privacy-policy.html' exact component={Privacy} />
            <Route path='/en/user-agreement.html' exact component={UserAgreement} />
            <Route path='/cn' exact component={HomeCN} />
            <Route path='/privacy-policy.html' exact component={PrivacyCN} />
            <Route path='/user-agreement.html' exact component={UserAgreementCN} />
            <Route path="*" component={Home} />
          </Switch>
          <Footer />
      </Router>
        
    
  );
}

export default App;
