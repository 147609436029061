import React from 'react';
import { NavbarPT , PrivacyPolicyPT } from '../../components';

function Privacy() {
  return (
    <>
    <NavbarPT />
    <PrivacyPolicyPT />
    </>
  );
}

export default Privacy;