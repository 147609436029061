import React from 'react';
import { NavbarPT , UserAgreementPT } from '../../components';

function UserAgreePT() {
  return (
    <>
    <NavbarPT />
    <UserAgreementPT />
    </>
  );
}

export default UserAgreePT;